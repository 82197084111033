import React from "react";
import ImageGallery from "./ImageGallery/ImageGallery";
import "./App.css";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <h1>Artoreal Gallery</h1>
      </header> */}
      <main>
        <ImageGallery />
      </main>
    </div>
  );
}

export default App;
