import React, { useState, useEffect } from "react";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ImageGallery.css";
import logo from "../artoreal_logo.svg";
import loading from "../loading-please-wait.gif";

const ImageGallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const refImg = React.useRef(null)

  const [imageData, setImageData] = useState([]);
  let urlKey = window.location.search.replace(/[?&]collection=/, "");

  useEffect(() => {
    // Call the function when the component mounts
    fetchData();
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsMobile(window.innerWidth < 665);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 665);
    };

    // Initial check
    handleOrientationChange();

    // Add event listeners for orientation change and resize
    window.addEventListener("orientationchange", handleOrientationChange);
    window.addEventListener("resize", handleResize);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchData = async () => {
    try {
      const info = await fetch("https://api.artoreal.com/rest/V1/collection", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          collection: urlKey,
        }),
      });
      const result = await info.json();
      setImageData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const artworks = imageData?.map((item) => ({
    original: item.imageURL,
    title: item.title,
    artist: `By ${item.artist}`
  }));

  const ThumbnailContainer = ({
    images,
    currentIndex,
    handleThumbnailClick,
  }) => {
    const startIdx = Math.max(0, currentIndex - 5);
    const endIdx = Math.min(images.length - 1, currentIndex + 5);

    const visibleThumbnails = images.slice(startIdx, endIdx + 1);

    return (
      <div style={{ display: "flex", marginTop: "10px" }}>
        {visibleThumbnails.map((image, index) => (
          <div
            key={index}
            style={{ marginRight: "5px", cursor: "pointer" }}
            onClick={() => handleThumbnailClick(index + startIdx)}
          >
            <img
              src={image.original}
              alt={`Thumbnail ${index + startIdx}`}
              style={{ width: "100px", height: "100px", objectFit: "cover" }}
            />
          </div>
        ))}
      </div>
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="gallery-container">
      {isMobile ? (
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            backgroundColor: "#f8d7da",
            color: "#721c24",
          }}
        >
          <strong>Please rotate your device for a better experience.</strong>
        </div>
      ) : imageData.length > 0 ? (
        <Gallery
          items={artworks}
          ref={refImg}
          showBullets={false}
          showFullscreenButton={true}
          showPlayButton={true}
          showThumbnails={true}
          autoPlay={true}
          slideInterval={9000}
          lazyLoad={true}
          showNav={true}
          useBrowserFullscreen={true}
          onClickThumbnail={handleThumbnailClick}
          currentIndex={currentIndex}
          renderItem={(item, props) => {
            const ImageExtenstion = item.original
              .split(/[#?]/)[0]
              .split(".")
              .pop()
              .trim();
            const zoomImageUrl = item.original.replace(
              "." + ImageExtenstion,
              "1600_width_96." + "webp"
            );
            return (
              <div className="image-gallery-custom-slide">
                <div
                  className="image-gallery-description"
                  style={{ position: "unset" ,lineHeight:"0.5"}}
                >
                  Collection Name :{" "}
                  {urlKey
                    .split("-")
                    .map((a) => a.charAt(0).toUpperCase() + a.substring(1))
                    .join(" ")}
                </div>
                <img
                  src={zoomImageUrl}
                  alt={item.description}
                  className="image-size"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = item.original;
                  }}
                />
                <div className="description">
                  <span><b>{refImg?.current?.getCurrentIndex()+1+". "}{item.title}</b></span><br/>
                  <span>
                    {item.artist}
                    </span>
                </div>
                <img src={logo} alt="artoreal" className="image-container" />
              </div>
            );
          }}
        />
      ) : (
        <img
          src={loading}
          alt="loading"
          className="image-size"
          style={{ width: "100%" }}
        />
      )}
      {/* <ThumbnailContainer
        images={artworks}
        currentIndex={currentIndex}
        handleThumbnailClick={handleThumbnailClick}
      /> */}
    </div>
  );
};

export default ImageGallery;
